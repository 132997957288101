<template>


  <div v-if="!is_load">

    <ol class="breadcrumb">
      <li class="breadcrumb-item">Trang chủ</li>
      <li class="breadcrumb-item">
       
        <router-link to="/admin/location/lists"> Quản lý Tỉnh/ Thành phố</router-link>
      </li>

      <li class="breadcrumb-item"> Tỉnh/ Thành phố</li>
    </ol>
     <FormValidate v-slot="{ invalid ,reset }">

    <form   @submit.prevent="onSubmit" @reset.prevent="reset" id="sendform">
      
      <div class="container">
        <ul class="nav nav-tabs" role="tablist" style="display: none;" >
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#home"
              >Tiếng Việt</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#menu1">English</a>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">

      

          <div id="home" class="container tab-pane active">
            <br />
            <div class="">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header">
                    <strong>Thông tin chung </strong>
                    <small>Form</small>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-10">
                       
                        <div class="form-group">
                          <label for="name"
                            >Tỉnh/Thành phố   <span class="text-danger">*</span></label
                          >
                        

                           <InputValidate name="Tỉnh/Thành phố " rules="required" v-slot="{ errors }">
                              <input v-model="Form.name" type="name" name="name" class="form-control" />
                              <span class='text text-danger'>{{ errors[0] }}</span>
                            </InputValidate>
                                               
                            
                        </div>
                       
                      </div>

                      <div class="col-sm-10">
                        <div class="form-group">
                          <label for="name"
                            >Mã số  <span class="text-danger">*</span></label
                          >
                          
                            
                           <InputValidate name="Mã số " rules="required" v-slot="{ errors }">
                              <input v-model="Form.code" type="code" name="code"  class="form-control" />
                              <span class='text text-danger'>{{ errors[0] }}</span>
                            </InputValidate>
                          
                        </div>
                      </div>

                        




                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="menu1" class="container tab-pane fade">
              <div class="row">

                      


                    </div>
          </div>
          <div
            class="card-footer"
            style="width: 90%; position: fixed; bottom: 0"
          >
            <button
              type="submit"
              class="btn btn-sm btn-primary"
             
            >
              <i class="fa fa-save"></i> Save
            </button>
             <button
              type="reset"
              class="btn btn-sm btn-warning ml-3"
              @click="myreset"
               :disabled="invalid"
            >
              <i class="fa fa-save"></i> Reset
            </button>
            
             
    
           
          </div>
        </div>
      </div>
    </form>
  </FormValidate>
  </div>
  <div v-else>
        <MyLoadTheme>
             
                <MyLoading v-for="k in 10" :key="k"></MyLoading>
           
        </MyLoadTheme>
  </div>
</template>

<script>
import axios from "axios";


export default {
  data() {
    return {
      is_load: false,
      img_one: "",
     
     
      list_cate: null,
      isLogin: null,
      Form: {
          id:"",
          name:'',
          code:""
      },

     
     
    };
  },
  created() {
    let isLogin = this.$session.get("isLogin");
    this.isLogin = isLogin.permistions.split(",");
    
  },

  mounted() {
       this.is_load=true;
           axios
          .get(window.my_api + "api/location/get-location-by-id", {params:{ "locationId":this.$route.params.id }}, {
            headers: window.my_header,
          })
          .then((res) => {
                if(res.status==200){
                    
                    this.Form.name=res.data.data.name;
                    this.Form.code=res.data.data.code;
                    this.Form.id=res.data.data.id;


                }
                this.is_load=false;
          });
  },
  methods: {
    
    onPicturefile(e) {
      let _this = this;
      var file = e.target.files[0];
      var reader = new FileReader();
      reader.onloadend = function () {
        _this.img_one = reader.result;
      };
      reader.readAsDataURL(file);
    },
    myreset(){
        
                 Object.keys(this.Form).forEach(v => {
                      this.Form[v]="";
                  });
    },
    onSubmit: function () {
        let myForm = document.getElementById("sendform");

       let formData = new FormData(myForm);

       formData.append('id',this.Form.id);
       
       formData.append("status",0);
       formData.append("name_en",this.Form.name);
       formData.append("code_en",this.Form.code);  
                
        
        axios
          .post(window.my_api + "api/location/create-or-update-location", formData, {
            headers: window.my_header,
          })
          .then((res) => {

            if (res.status == 200) {
               if(res.data.successed){
                    this.$swal.fire({
                        position: "top-end",
                        icon: "success",
                        title: "Thêm thành công.",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                      
                      
               }else{
                      alert("Vui lòng kiểm tra lại dữ liệu");
                      
                     
               }
            

            } else {
              alert("Thêm không thành công, vui lòng liên hệ admin");
             
            }
             this.is_load = false;
          });
      
    },
  },
};
</script>